import dayjs from 'dayjs';

export const useComparisonApi = () => {
  // Composable
  const { client } = useApiV2();

  // Store
  const { getPeriod, getEnergyType, getAreaId } = storeToRefs(exploreStore());
  const { setLoading } = exploreStore();
  const { setComparisonResponse, setComparisonError } = comparisonStore();
  const { areasToCompare, aggregationLevel, zoomPeriod } = storeToRefs(comparisonStore());

  // Data
  const abortComparisonController = ref<AbortController | null>(null);
  const currentComparisonRequestId = ref<string | null>(null);

  // Methods
  // ============ Get Consumption for one area ============
  const getComparisonConsumption = async () => {
    const requestId = dayjs().toString();
    currentComparisonRequestId.value = requestId;

    if (abortComparisonController.value) {
      abortComparisonController.value.abort();
    }

    const dateFormat = ['minute', 'hour'].includes(aggregationLevel.value) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
    const start = zoomPeriod.value ? dayjs(zoomPeriod.value.start).format(dateFormat) : dayjs(getPeriod.value.start).format(dateFormat);
    const end = zoomPeriod.value ? dayjs(zoomPeriod.value.end).format(dateFormat) : dayjs(getPeriod.value.end).format(dateFormat);

    try {
      abortComparisonController.value = new AbortController();
      setComparisonResponse(null);
      setComparisonError(null);
      setLoading(true);

      const reponse = await client.POST('/v2/areas/{area_id}/explore/compare', {
        params: {
          query: {
            energy_type: getEnergyType.value,
            start,
            end,
            aggregation_level: aggregationLevel.value,
          },
          path: {
            area_id: getAreaId.value,
          },
        },
        body: areasToCompare.value.map((area) => ({
          area_id: area.id,
          start: ['minute', 'hour'].includes(aggregationLevel.value)
            ? dayjs(area.periodStart).format('YYYY-MM-DD HH:mm')
            : dayjs(area.periodStart).format('YYYY-MM-DD'),
        })),
        signal: abortComparisonController.value?.signal,
      });

      if (reponse.data.data) {
        setComparisonResponse(reponse.data.data);
      } else {
        setComparisonError(reponse.data.error);
        setComparisonResponse(null);
      }
      setLoading(false);
    } catch (error) {
      if (error.name !== 'AbortError') {
        setComparisonResponse(null);
        setComparisonError({
          message: error.message,
          status_code: 500,
          error_code: 'no_response',
        });
        setLoading(false);
        throw error;
      }
    }
  };

  return { getComparisonConsumption };
};
