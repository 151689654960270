<script setup lang="ts">
// Components
import ComparisonChart from '../../components/chart/comparison.vue';

// Stores
const { areaId, period, energyType, chartType } = storeToRefs(exploreStore());
const { areasToCompare, zoomPeriod, aggregationLevel } = storeToRefs(comparisonStore());
const { setAggregationLevel } = comparisonStore();

// Composables
const { getKeysFromForage, setKeysInForage } = useExploreStorage();
const { getComparisonConsumption } = useComparisonApi();
const { getFiltersFromStorage, setFiltersInStorage } = useComparisonStorage();
const { getAggregationLevelFromPeriod } = useExploreFilter();

// Data
const watcher = ref();

// Lifecycle
onMounted(async () => {
  await getKeysFromForage();
  await getFiltersFromStorage();

  // Watch change values after the first call
  if (chartType.value === 'explore-comparison') {
    watcher.value = watch(
      [
        () => areaId.value,
        () => period.value,
        () => energyType.value,
        () => areasToCompare.value,
        () => aggregationLevel.value,
        () => zoomPeriod.value,
      ],
      async () => {
        await setKeysInForage();
        await setFiltersInStorage();

        const aggregationLevels = getAggregationLevelFromPeriod(zoomPeriod.value ?? period.value);
        if (!aggregationLevels.includes(aggregationLevel.value) && aggregationLevels[0]) {
          setAggregationLevel(aggregationLevels[0]);
        } else {
          await getComparisonConsumption();
        }
      },
      { deep: true },
    );
  }

  const aggregationLevels = getAggregationLevelFromPeriod(zoomPeriod.value ?? period.value);
  if (!aggregationLevels.includes(aggregationLevel.value)) {
    setAggregationLevel(aggregationLevels[0]);
  } else {
    await getComparisonConsumption();
  }
});

onBeforeUnmount(() => {
  if (watcher.value) {
    watcher.value();
  }
});
</script>
<template>
  <div class="w-full flex-1 flex flex-col gap-4">
    <div class="w-full h-full">
      <ui-card
        class="w-full h-full min-h-0 overflow-hidden"
        :class="zoomPeriod ? '!border-green-500 ring-offset-0 ring-[1px] ring-green-100' : ''"
      >
        <comparison-chart />
      </ui-card>
    </div>
  </div>
</template>
