import localforage from 'localforage';
import type { AggregationLevels } from '../../types';

export const useComparisonStorage = () => {
  // Stores
  const { selectedClient, selectedSite } = storeToRefs(globalStore());
  const { getPeriod } = storeToRefs(exploreStore());
  const { aggregationLevel, zoomPeriod } = storeToRefs(comparisonStore());
  const { setAggregationLevel, setZoomPeriod } = comparisonStore();

  // Composables
  const { getAggregationLevelFromPeriod } = useExploreFilter();

  const getFiltersFromStorage = async () => {
    // Aggregation levels
    const aggregationLevels = getAggregationLevelFromPeriod(getPeriod.value);
    const aggregationLevelFromStorage = await localforage.getItem<AggregationLevels>(
      `nrjx-${selectedClient.value}-${selectedSite.value[0]}-comparison-aggregation-level`,
    );

    if (!aggregationLevels.includes(aggregationLevelFromStorage)) {
      setAggregationLevel(aggregationLevels[0]);
    } else {
      setAggregationLevel(aggregationLevelFromStorage);
    }

    // Zoom period
    const zoomPeriodFromStorage = await localforage.getItem<string>(
      `nrjx-${selectedClient.value}-${selectedSite.value[0]}-comparison-zoom-period`,
    );
    const zoomPeriodFromStorageParsed = JSON.parse(zoomPeriodFromStorage);
    if (zoomPeriodFromStorageParsed) {
      setZoomPeriod(zoomPeriodFromStorageParsed);
    }
  };

  const setFiltersInStorage = () => {
    localforage.setItem(`nrjx-${selectedClient.value}-${selectedSite.value[0]}-comparison-aggregation-level`, aggregationLevel.value);
    localforage.setItem(`nrjx-${selectedClient.value}-${selectedSite.value[0]}-comparison-zoom-period`, JSON.stringify(zoomPeriod.value));
  };

  return {
    getFiltersFromStorage,
    setFiltersInStorage,
  };
};
