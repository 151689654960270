<script setup lang="ts">
import { theme } from '#tailwind-config';
import dayjs from 'dayjs';

// Store
const { comparisonResponse, comparisonError, zoomPeriod, areasToCompare, aggregationLevel } = storeToRefs(comparisonStore());
const { getLoading, getAreaId, period } = storeToRefs(exploreStore());
const { updateAreasToCompare, setZoomPeriod } = comparisonStore();

// Composables
const { options } = useComparisonChart();
const { updateAreaStartPeriod } = useComparisonFilter();

// Computed
const principalArea = computed(() => areaStore().getArea(getAreaId.value));
const compareAreas = computed(
  () =>
    comparisonResponse.value?.compared_to?.map((item) => {
      const areaColor = areasToCompare.value.find((area) => area.id === item.area_id)?.color ?? 'green';
      return {
        label: areaStore().getArea(item.area_id)?.name ?? '',
        data: item.consumption.items.map((item) => item.avg_power),
        color: theme.colors[areaColor]?.['500'] ?? '#1BA774',
      };
    }) ?? [],
);
const datasets = computed(() => [
  {
    label: principalArea.value?.name ?? '',
    data: comparisonResponse.value?.reference.items.map((item) => item.avg_power) ?? [],
    color: '#1BA774',
  },
  ...compareAreas.value,
]);
const timestamps = computed(() => comparisonResponse.value?.reference.items.map((item) => dayjs(item.start).toDate()) ?? []);

// Methods
const setPeriod = (period: { start: Date; end: Date }) => {
  const startIndex = timestamps.value.findIndex((timestamp) => dayjs(timestamp).isSame(period.start, aggregationLevel.value));

  // Set the zoom period for parent area
  const principalZoneTimestampStart = timestamps.value.findIndex((timestamp) =>
    dayjs(timestamp).isSame(period.start, aggregationLevel.value),
  );
  const principalZoneTimestampEnd = timestamps.value.findIndex((timestamp) => dayjs(timestamp).isSame(period.end, aggregationLevel.value));

  setZoomPeriod({ start: timestamps.value[principalZoneTimestampStart], end: timestamps.value[principalZoneTimestampEnd] });

  // Set the zoom period for compared areas
  const areaUpdated = areasToCompare.value.map((area) => {
    const areaCompareList = comparisonResponse.value?.compared_to;
    const areaConsumption = areaCompareList.find((item) => item.area_id === area.id)?.consumption.items.map((item) => item.start);
    const areaStartTimestamp = areaConsumption[startIndex];
    return {
      id: area.id,
      periodStart: dayjs(areaStartTimestamp).toDate(),
      color: area.color,
    };
  });
  updateAreasToCompare(areaUpdated);
};

const handleUnzoom = () => {
  setZoomPeriod(null);
  areasToCompare.value.forEach((area) => {
    updateAreaStartPeriod(area.id, zoomPeriod.value?.start ?? period.value.start);
  });
};
</script>
<template>
  <nrjx-chart-line
    title="kW"
    :options="options"
    :loading="getLoading"
    :data="{
      labels: timestamps,
      datasets: datasets,
    }"
    :is-zoomed="zoomPeriod !== null"
    @period-selected="setPeriod"
    @unzoom="handleUnzoom"
  >
    <template #no-data>
      <div v-if="comparisonError" class="flex flex-col items-center justify-center h-full">
        <div class="flex flex-col items-center justify-center border border-orange-500 bg-orange-100 rounded-md p-4">
          <ui-icon name="TriangleAlert" class="text-orange-500 mb-4" />
          <p class="text-orange-500 text-sm">{{ $t(`error.${comparisonError?.error_code}`) }}</p>
        </div>
      </div>
    </template>
  </nrjx-chart-line>
</template>
