import { theme } from '#tailwind-config';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import type { TooltipData } from '~/components/nrjx/chart/tooltip/index.vue';

dayjs.extend(isoWeek);

export const useComparisonChart = () => {
  // Composables
  const { formatNumberToIsoNumber } = useNumbers();
  const { getFormattedDateFromAggregateLevel } = useChart();

  // Stores
  const { getComparisonResponse, aggregationLevel, areasToCompare } = storeToRefs(comparisonStore());
  const { areaId } = storeToRefs(exploreStore());

  const options = ref({
    x: {
      ticks: {
        callback: (value: number) => {
          return getFormattedDateFromAggregateLevel(getComparisonResponse.value?.reference.items[value].start, aggregationLevel.value);
        },
      },
    },
    tooltip: (context) => {
      const rootAreaConsumption = getComparisonResponse.value.reference.items[context[0].index];
      const rootAreaName = areaStore().getArea(areaId.value)?.name;

      const comparedAreas = getComparisonResponse.value.compared_to.map((comparedArea, index) => {
        const areaColor = areasToCompare.value.find((el) => el.id === comparedArea.area_id)?.color ?? 'green';
        const color: string = theme.colors[areaColor]?.['500'] ?? '#1BA774';

        return {
          color: color,
          title: areaStore().getArea(comparedArea.area_id)?.name,
          value: `${formatNumberToIsoNumber(Number(comparedArea.consumption.items[context[index].index].avg_power), 2)} kW`,
        };
      });

      const tooltipData: TooltipData = {
        body: [
          {
            color: theme.colors.primary[500],
            title: rootAreaName,
            value: `${formatNumberToIsoNumber(Number(rootAreaConsumption.avg_power), 2)} kW`,
          },
          ...comparedAreas,
        ],
        footer: getFormattedDateFromAggregateLevel(rootAreaConsumption.start, aggregationLevel.value),
      };
      return tooltipData;
    },
  });

  return {
    options,
  };
};
